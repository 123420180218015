/* @flow */
import * as React from 'react'
import { Fragment } from 'react'
import cx from 'classnames'

import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import { Link, FormattedMessage } from "gatsby-plugin-intl"
import styles from '../layouts/styles.module.css'

import { SquareContainer, SquareRow, SquareBox } from '../layouts'

const FormattedMessageValues = {
  strong: (str: string) => <strong>{str}</strong>,
  span: (str: string) => <span>{str}</span>,
}


const IndexPage = (): React.Node => {
  return (
  <Fragment>
      <section className={cx(styles.hero)}>
        <div>
          <div>
            <p>
              Well that is sad. <br />
              This page doesn't exist. <br />
            <button onClick={() => navigate(-1)}>Go Back</button>
          </p>
          </div>
        </div>
      </section>

    </Fragment>
  )
}

export default IndexPage
